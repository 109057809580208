<template>
    <div>
        <Navbar />
        <div class="wrapper">
            <router-view />
            <Footer />
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar'
    import Footer from '@/components/Footer'

    export default {
        metaInfo: {
            titleTemplate: '%s | Tixgo'
        },
        components: {
            Navbar,
            Footer,
        },
        methods: {}
    }
</script>

<style scoped>
    .wrapper{
        background-color:#F2F5F7;
    }
</style>