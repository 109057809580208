<template>
  <div>
    <Navbar />
    <div class="wrapper">
      <b-container fluid>
        <b-row>
          <b-col md="5" lg="4" xl="3" class="d-none d-lg-flex">
            <b-card class="sidebar w-100">
              <ProfileSidebar/>
            </b-card>
          </b-col>
          <b-col><router-view /></b-col>
        </b-row>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
  
<script>
  import Navbar from '@/components/Navbar'
  import Footer from '@/components/Footer'
  import ProfileSidebar from '@/shared/components/ProfileSidebar.vue';

  export default({
    metaInfo: {
        titleTemplate: '%s | Tixgo'
    },
    components: {
      Navbar,
      Footer,
      ProfileSidebar
    }
  })
</script>
  
<style scoped>
.sidebar{
  border-radius:0px;
}
.wrapper{
    background-color:#F2F5F7;
}
</style>