import { defineStore } from 'pinia'

export const useShopStore = defineStore('counter', {
  state: () => ({
    sidebar_visible: false,
  }),
  actions: {
    toggleSidebar() {
      if (this.sidebar_visible) {
        this.sidebar_visible = false;
      } else {
        this.sidebar_visible = true;
      }
    },
    foldSidebar() {
      this.sidebar_visible = false;
    },
    isSidebarVisible() {
      return this.sidebar_visible;
    },
  }
})